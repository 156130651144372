<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label='行政区域等级：'>
                    <el-select v-model="filters.level" placeholder="请选择" @change='getAreaDictionaryList' clearable>
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='行政区域名称：'>
                    <el-input v-model='filters.name' placeholder='请输入' clearable @clear='handleClear' @input='() => (filters.name = filters.name.replace(/\s/g,""))' @keyup.enter.native='getAreaDictionaryList'></el-input>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格主体 -->
        <el-table
        border
        highlight-current-row
        v-loading="listLoading"
        :header-cell-style="headStyle"
        :cell-style='cellStyle'
        :data="treeData"
        @current-change="currentChange"
        row-key='ID'
        lazy
        max-height='680'
        :load="load"
        :tree-props="{children: 'children', hasChildren: 'HasChildren'}"
        style="width: 100%"
        ref="multipleTable"
        v-if='isAlive'
        :indent=60>
            <el-table-column type="index" label="#" width="60">
                <template slot-scope='scope'>
                    <i class="el-icon-time" v-if='scope.row.Level == 0'></i>
                </template>
            </el-table-column>
            <el-table-column prop="Name" label="名称"></el-table-column>
            <el-table-column prop="Code" label="编码"></el-table-column>
            <el-table-column prop="IsEnable" label="启用标识">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsEnable' type='success'>启用</el-tag>
                    <el-tag v-else type='warning'>禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="Remarks" label="备注" show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 新增 -->
        <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        :close-on-click-modal='false'
        @close='handleAddClose'
        width="30%">
        <el-form :model="form" :rules="formRules" ref="formRef" label-width="100px">
            <el-form-item label='父级节点：' prop='parentId' v-if='parentIdName'>
                <el-input v-model='parentIdName' :disabled='true'></el-input>
            </el-form-item>
            <el-form-item label='名称：' prop='name'>
                <el-input v-model='form.name' @input='() => (form.name = form.name.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label='编码：' prop='code'>
                <el-input v-model='form.code' type='number' @input='() => (form.code = form.code.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label='启用标识：' prop='isEnable'>
                <el-select v-model="form.isEnable" placeholder="请选择">
                    <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='备注：' prop='remarks'>
                <el-input v-model='form.remarks' type='textarea'></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addDialogSubmit">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 编辑 -->
        <el-dialog
        title="编辑"
        :visible.sync="editDialogVisible"
        :close-on-click-modal='false'
        width="30%">
        <el-form :model="editform" :rules="editformRules" ref="editformRef" label-width="100px">
            <el-form-item label='名称：' prop='name'>
                <el-input v-model='editform.name' @input='() => (editform.name = editform.name.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label='编码：' prop='code'>
                <el-input v-model='editform.code' type="number" @input='() => (editform.code = editform.code.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label='启用标识：' prop='isEnable'>
                <el-select v-model="editform.isEnable" placeholder="请选择">
                    <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='备注：' prop='remarks'>
                <el-input v-model='editform.remarks' type='textarea'></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editDialogSubmit">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 抓取 -->
        <el-dialog
        title="抓取"
        :visible.sync="dialogVisible"
        width="30%"
        @close='handleWebUrlClose'
        :close-on-click-modal='false'>
        <el-form :model="webUrlform" :rules="webUrlformRules" ref="webUrlformRef" label-width="100px">
            <el-form-item label='网站地址：' prop='webUrl'>
                <el-input v-model='webUrlform.webUrl' :placeholder='defaultWebUrl' @input='() => (webUrlform.webUrl = webUrlform.webUrl.replace(/\s/g,""))'></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogSubmit" :loading="loading">{{text}}</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import Qs from 'qs';
import { getAreaDictionaryList,addAreaDictionary,updateAreaDictionary,deleteAreaDictionary,grabAreaDictionary } from '@/api/api'
export default {
    components:{ Toolbar },
    data(){
        var checkCode = (rule,value,callback) => {
            if(value.length == '6'){
                callback()
            }else{
                callback(new Error('长度为6位'))
            }
        }
        var checkLinkUrl = (rule,value,callback) => {
            var reg = /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/
            if(reg.test(value)){
                return callback()
            }else if(value == ''){
                return callback()
            }else{
                return callback(new Error('链接地址不正确'))
            }
        }
        return {
            text:'确定',
            loading:false,
            buttonList:[],
            options:[
                {label:'省级',value:0},
                {label:'市级',value:1},
                {label:'县级',value:2}
            ],
            options1:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            currentRow:null,
            filters:{
                level:'',
                name:''
            },
            // 对话框显示控制
            addDialogVisible:false,
            editDialogVisible:false,
            dialogVisible:false,
            // 表单数据
            form:{
                name:'',
                code:'',
                parentId:null,
                isEnable:true,
                remarks:''
            },
            editform:{
                name:'',
                code:'',
                parentId:null,
                isEnable:true,
                remarks:''
            },
            webUrlform:{
                webUrl:''
            },
            defaultWebUrl:'http://preview.www.mca.gov.cn/article/sj/xzqh/2020/2020/202101041104.html',
            formRules:{
                name:[{required:true,message:'请输入名称',trigger:'blur'}],
                code:[
                    {required:true,message:'请输入编码',trigger:'blur'},
                    {validator:checkCode,trigger:'blur'}
                ],
                isEnable:[{required:true,trigger:'blur'}],
                remarks:[{message:'请输入备注',trigger:'blur'}]
            },
            editformRules:{
                name:[{required:true,message:'请输入名称',trigger:'blur'}],
                code:[
                    {required:true,message:'请输入编码(6位)',trigger:'blur'},
                    {validator:checkCode,trigger:'blur'}
                ],
                isEnable:[{required:true,trigger:'blur'}],
                remarks:[{message:'请输入备注',trigger:'blur'}]
            },
            webUrlformRules:{
                webUrl:[
                    {required:true,message:'请输入网址',trigger:'blur'},
                    {validator:checkLinkUrl,trigger:'blur'}
                ]
            },
            // 树形表格数据
            treeData:[],
            listLoading:false,
            // 级联选择数据
            defaultProps:{
                children: "children",
                label: "name",
                value:'id',
                lazy:true,
                lazyLoad(node, resolve){
                    var params = {}
                    if(node.data){
                        params.parentId = node.data.id
                    }else{
                        params.parentId = 0
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var allData = res.data.Response.reverse()
                            var Nodes = allData.map((item,index) => ({
                                id:item.ID,
                                name:item.Name
                            })) 
                            resolve(Nodes)
                        }
                    }).catch(err => err)
                }
            },
            parentIdName:'',
            isAlive:true, // 控制刷新
            levelControl:false,
        }
    },
    methods:{
        // 表格样式
        headStyle(){
            return 'text-align:center;'
        },
        cellStyle(){
            return 'text-align:center;'
        },
        // 筛选框清空重新请求数据
        handleClear(){
            this.getData()
        },
        // 查询
        getAreaDictionaryList(){
            if(this.filters.name){
                var params = {
                    level:this.filters.level,
                    name:this.filters.name
                }
            }else{
                var params = {
                    level:this.filters.level || 0,
                    name:this.filters.name
                }
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    this.treeData = res.data.Response
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message)
                }               
            }).catch(() => {})
        },
        // 新增
        handleAdd(){
            var row = this.currentRow
            if(row && row.Level == 2){
                this.$message.warning('最低等级为区县级')
                this.addDialogVisible = false
            }else{
                this.addDialogVisible = true
            }          
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(row == null){
                this.$message.warning('请选择要编辑的一行数据')
            }else{
                this.editform.id = row.ID,
                this.editform.name = row.Name,
                this.editform.code = row.Code,
                this.editform.parentId = row.ID,
                this.editform.isEnable = row.IsEnable,
                this.editform.remarks = row.Remarks
                this.editDialogVisible = true
            }
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(row == null){
                this.$message.warning('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteAreaDictionary(Qs.stringify({id:row.ID})).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.reload()
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }          
        },
        // 抓取
        handleGrab(){
            this.dialogVisible = true
            this.text = '确定'
        },
        // 新增表单提交
        addDialogSubmit(){
            this.$refs['formRef'].validate((valid) => {
                if(valid){
                    var params = {
                        name:this.form.name,
                        code:String(this.form.code),
                        isEnable:this.form.isEnable,
                        remarks:this.form.remarks
                    }
                    if(this.form.parentId){
                        params.parentId = this.form.parentId
                    }
                    addAreaDictionary(params).then(res => {
                        this.addDialogVisible = false
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.reload()
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }else{return}
            })
        },
        // 编辑表单提交
        editDialogSubmit(){
            this.$refs['editformRef'].validate((valid) => {
                if(valid){
                    var params = {
                        id: this.editform.id,
                        name: this.editform.name,
                        code: String(this.editform.code),
                        isEnable: this.editform.isEnable,
                        remarks: this.editform.remarks
                    }
                    if(this.editform.parentId){
                        params.parentId = this.editform.parentId
                    }
                    updateAreaDictionary(params).then(res => {
                        this.editDialogVisible = false
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.reload()
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }else{return}
            })
            
        },
        // 抓取表单提交
        dialogSubmit(){
            if(this.webUrlform.webUrl == ''){
                this.webUrlform.webUrl = this.defaultWebUrl
            }
            this.$refs['webUrlformRef'].validate((valid) => {
                if(valid){
                    this.loading = true
                    this.text = '抓取中'
                    grabAreaDictionary(Qs.stringify({webUrl:this.webUrlform.webUrl})).then(res => {                               
                        if(res.data.Success){
                            this.loading = false
                            this.dialogVisible = false
                            this.$message.success('抓取成功')
                            window.location.reload() 
                        }else{
                            this.loading = false
                            this.dialogVisible = false
                            this.$message.error('抓取失败')
                        }
                    }).catch(() => {
                        setTimeout(() => {
                            this.loading = false
                            this.dialogVisible = false
                        },3000)                      
                    })
                }else{
                    return
                }
            })
            
        },
        // 获取页面列表数据
        getData(){
            this.listLoading = true
            var params = {
                level:0,
                parentId:null,
                name:this.filters.name
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    this.treeData = res.data.Response.reverse()
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message)
                }               
            }).catch(() => {})
        },
        // 树形表格事件处理
        currentChange(val){
            this.currentRow = val
            this.parentIdName = val.Name
            this.form.parentId = val.ID
        },
        load(tree, treeNode, resolve){    
            var params = {
                parentId:tree.ID
            }     
            getAreaDictionaryList(params).then(res => {
                resolve(res.data.Response.reverse())
            })
        },
        // 刷新列表页面
        reload(){
            this.isAlive = false
            this.$nextTick(() => this.isAlive = true)
        },
        // 对话框关闭清空表单
        handleAddClose(){
            this.$refs['formRef'].resetFields()
            this.form = {}
        },
        handleEditClose(){
            this.$refs['editformRef'].resetFields()
            this.editform = {}
        },
        handleWebUrlClose(){
            this.$refs['webUrlformRef'].resetFields()
            this.webUrlform.webUrl = ''
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
    },
    mounted(){
        this.getData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>